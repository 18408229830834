import MeetingProvider from './meeting/MeetingProvider';
import {
  MeetingConsumer,
  useMeeting
} from './meeting/meetingProviderContextDef';
import useParticipant from './participant/useParticipant';
import useConnection from './connection/useConnection';
import usePubSub from './pubSub/usePubSub';
import { VideoSDK } from '@videosdk.live/js-sdk';

const Constants = VideoSDK.Constants;

const createCameraVideoTrack = async ({
  cameraId = undefined,
  encoderConfig = undefined,
  facingMode = undefined,
  optimizationMode = undefined
}) => {
  return await VideoSDK.createCameraVideoTrack({
    cameraId,
    encoderConfig,
    facingMode,
    optimizationMode
  });
};

const createScreenShareVideoTrack = async ({
  encoderConfig = undefined,
  optimizationMode = undefined
  // withAudio = "enable" | "disable" | "auto"
}) => {
  return await VideoSDK.createScreenShareVideoTrack({
    encoderConfig,
    optimizationMode
  });
};

const createMicrophoneAudioTrack = async ({
  noiseConfig = undefined,
  encoderConfig = undefined,
  microphoneId = undefined
}) => {
  return await VideoSDK.createMicrophoneAudioTrack({
    noiseConfig,
    encoderConfig,
    microphoneId
  });
};

export {
  MeetingProvider,
  useMeeting,
  useParticipant,
  usePubSub,
  MeetingConsumer,
  useConnection,
  Constants,
  createCameraVideoTrack,
  createMicrophoneAudioTrack,
  createScreenShareVideoTrack
};
